import schttp from 'public/src/services/schttp'
// 请求参数处理
const getFetchParams = (options) => {
  const { catInfo, request } = options
  const { sub_type, select_id, store_code } = catInfo



  const data = {
    store_code,
    type: sub_type,
    search_type: 'store',
    routeId: select_id,
    ...request.query,
    requestType: request.type,
    top_cate_id: undefined,
    main_cate_id: undefined,
    main_goods_id: undefined, //wiki:pageId=1413949449
  }

  const resultParams = {}
  // 过滤
  Object.entries(data).forEach(([key, value]) => {
    if (value === null || value === undefined || value === '') return
    if (Number.isNaN(value)) return
    if ((key === 'min_price' || key === 'max_price') && value !== '') {
      resultParams[key] = decodeURIComponent(value)
      return
    }
    resultParams[key] = decodeURIComponent(value)
  })

  return resultParams
}


export function getStoreItems(params, options = {}) {
  return schttp({
    url: '/api/store/items/get',
    params: getFetchParams(params),
    ...options
  })
}
