
import { getRankListComponentBff } from './action_flow.js'

const actions = {
  dealingNewData ({ commit, dispatch }, payload) {
    commit('dealingFilterAttr', payload)
    commit('dealingSelectedFilter', payload)
    commit('dealingResults', payload)
    commit('dealingGoods', payload)
    dispatch('dealingFlowData')
  },
  async dealingFlowData ({ state, getters }) {
    if (typeof window === 'undefined') {
      return
    }
    const rankListComponent = await getRankListComponentBff({ getters }) || {}
    state.Results.flowSlot = Object.assign({}, state.Results.flowSlot, rankListComponent)
  },
}

export default actions
