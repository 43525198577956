import schttp from 'public/src/services/schttp'
import { goodsTransformation } from 'public/src/services/goodsItemInfo/goodsTransformation/index.js'

const isInListSearch = (query) => { // 是否是列表页内搜索
  return !(query?.sil_without_entry || !query?.sil_type)
}
const getSearchPoskey = (query) => {
  return !isInListSearch(query) ? 'SearchPageSort' : 'ListSearchSort'
}

export const bffHypernymGoods = async ({
  query, 
  catInfo,
  lang,
  language,
  sheinClubInfo
}) => {
  const { keywords, page, limit, viewedGoods = [] } = query
  const hypernymGoodsParams = {
    keywords,
    limit,
    page,
    search_goods: (viewedGoods || []).join(),
    list_cat_id: isInListSearch(catInfo) ? catInfo.list_cat_id :  '',  // 商品列表分类ID,
    poskey: getSearchPoskey(catInfo)
  }
  const { info } = await schttp({
    method: 'GET',
    url: '/product/get_search_upper_recommend_products',
    params: hypernymGoodsParams,
    isPreLoad: true,
    useBffApi: true,
  })

  const { products, request_ext,  buriedPoint, num, empty_search_multi_lang, not_empty_search_multi_lang, suggestedSaleType }  = info
  const hypernymProduct = {
    sum: num || 0,
    // keywords,
    page,
    limit,
    emptyLang: empty_search_multi_lang,
    noEmptyLang: not_empty_search_multi_lang,
  }
  const transProducts =  products?.map?.(v => {
    const goodsInfo = goodsTransformation(v, {
      suggestedSaleType: suggestedSaleType || '',
      language: language ?? {},
      lang,
      isPaid: !!sheinClubInfo?.isPaid
    })
    goodsInfo.isHypernymGoods = true
    return goodsInfo
  })

  return { hypernymProduct, request_ext, buriedPoint, products: transProducts || [] }
}
