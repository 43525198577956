export class ListState {
  retainQueryObj = { 	 // 点击clear all重置时，保留的字段
    ici: 1,
    icn: 1,
    sort: 1,
    tag_ids: 1,
    cat_ids: 1,
    adp: 1,
    search_id: 1,
    search_cat: 1,
    srctype: 1,
    userpath: 1,
    is_from_list_feeds: 1,
    hrefTarget: 1,
    ShowTip: 1,
    CouponCode: 1,
    show_daily: 1,
    src_identifier: 1,
    src_module: 1,
    src_tab_page_id: 1,
    mallCode: 1,
    search_type: 1,
    keywords: 1,
    type: 1,
    routeId: 1,
    search_source: 1, // 搜索来源
    forceScene: 1,
    mall_tag: 1,
  }
  dailyRetainQueryObj = { 	 // 点击daily重置时，保留的字段
    ici: 1,
    icn: 1,
    sort: 1,
    cat_ids: 1,
    adp: 1,
    search_id: 1,
    search_cat: 1,
    srctype: 1,
    userpath: 1,
    is_from_list_feeds: 1,
    hrefTarget: 1,
    ShowTip: 1,
    CouponCode: 1,
    show_daily: 1,
    src_identifier: 1,
    src_module: 1,
    src_tab_page_id: 1,
    mallCode: 1,
    search_type: 1,
    keywords: 1,
    type: 1,
    routeId: 1,
    search_source: 1,
  }
  language = {}
  isSticky = false // 是否吸顶
  Results = {
    goods: [],
    staticFilterId: '',
    staticFilterType: '',
    flowSlot: {
      // @examples 流式数据插坑
      // 9: { flowCompTag: 'RANK_LIST_COMPONENT', goods: [] },
    },
  }
  Language = {}
}

export default new ListState()
