/**
 * @file 插坑相关action
 */

import { goodsTransformation } from 'public/src/services/goodsItemInfo/goodsTransformation/index.js'
import { fetchRankListComponent } from 'public/src/pages/product_list_v2/apis/index.js'

const getListPageParams = ({ catInfo, searchKeywords }) => {
  const params = {}
  if (catInfo.type === 'search') {
    Object.assign(params, {
      scene: 'search',
      word: searchKeywords?.is_suggest_res ? searchKeywords?.suggest_words : searchKeywords?.origin_words,
    })
  } else if (catInfo.type === 'entity') {
    Object.assign(params, {
      scene: 'real',
      cat_id: catInfo.entity_id,
    })
  } else if (catInfo.type === 'selection') {
    Object.assign(params, {
      scene: 'select',
      select_id: catInfo.select_id,
    })
  }
  return params
}

const getRankListComponentParams = ({ catInfo, cccConfig, goods, searchKeywords }) => {
  const end = (catInfo.page || 1) * (catInfo.limit || 10)
  const start = end - (catInfo.limit || 10)
  const deliverItemLimitObject = cccConfig?.flowRankingList?.deliverItemLimitObject || {}
  const deliveryPlaceArr = Object.keys(deliverItemLimitObject).filter(num => num >= start && num <= end)
  const expose_goods_id = catInfo.page <= 12 ? goods.slice(0, 240).map(item => item.goods_id).join(',') : ''
  const params = {
    deliveryPlaceArr,
    body: {
      sort: catInfo.sort || 0,
      expose_goods_id,
      ...getListPageParams({ catInfo, searchKeywords }),
    },
  }
  return params
}

const adaptRankListComponent = (rankListComponentResult, { language, sheinClubInfo, goods }) => {
  let _rankListComponentResult = rankListComponentResult?.info
  if (_rankListComponentResult && _rankListComponentResult.title && _rankListComponentResult.products?.length) {
    const suggestedSaleType = goods[0]?.pretreatInfo?.suggestedSalePriceConfigInfo?.type || ''
    const products = _rankListComponentResult.products.map(productItem => {
      return goodsTransformation(productItem, {
        suggestedSaleType,
        language: language || {},
        lang: gbCommonInfo.lang,
        isPaid: !!sheinClubInfo?.isPaid,
      })
    })
    _rankListComponentResult = Object.assign({}, _rankListComponentResult, {
      goods: products,
      suggestedSaleType,
    })
    delete _rankListComponentResult.products
  } else {
    _rankListComponentResult = null
  }
  return _rankListComponentResult
}

const processRankListComponent = ({ sum, cccConfig, rankListComponentResult }) => {
  if (!rankListComponentResult) {
    return null
  }
  const { delivery_place } = rankListComponentResult
  const flowRankCcc = cccConfig.flowRankingList
  if (!delivery_place || !flowRankCcc) {
    return null
  }
  const minimumProductImpressions = flowRankCcc.deliverItemLimitObject?.minimumProductImpressions
  if (minimumProductImpressions && sum < minimumProductImpressions) {
    return null
  }
  rankListComponentResult.flowCompTag = 'RANK_LIST_COMPONENT'
  return {
    [delivery_place]: rankListComponentResult,
  }
}

// 榜单内容体插坑
export async function getRankListComponentBff ({ getters }) {
  const { sum, language, sheinClubInfo, catInfo, cccConfig, goods, searchKeywords } = getters
  if (!['page_real_class', 'page_select_class', 'page_search'].includes(catInfo.pageName)) {
    return null
  }

  const params = getRankListComponentParams({ catInfo, cccConfig, goods, searchKeywords })
  if (!params.deliveryPlaceArr?.length) {
    return null
  }
  const pArr = params.deliveryPlaceArr.map(delivery_place => fetchRankListComponent({ ...params.body, delivery_place: Number(delivery_place) + 1 }))
  const results = await Promise.allSettled(pArr)
  return results.reduce((total, { value }) => {
    const rankListComponentResult = adaptRankListComponent(value, { language, sheinClubInfo, goods })
    const rankListComponent = processRankListComponent({ sum, cccConfig, rankListComponentResult })
    return {
      ...total,
      ...(rankListComponent || {}),
    }
  }, {})
}
