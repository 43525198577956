import { stringifyQueryString } from '@shein/common-function'
import { goodsTransformation } from 'public/src/services/goodsItemInfo/goodsTransformation/index.js'
import bffFilterAdapter from './bffFilterAdapter.js'

class BffAdapter {
  constructor({
    data,
    pageType,
    requestType,
    query,
    lastListData,
  }) {
    this.data = data || {}
    this.pageType = pageType
    this.requestType = requestType || ''
    this.query = query
    this.lang = this.data?.lang || (typeof window !== 'undefined' && gbCommonInfo.lang)
    this.#lastListData = lastListData || {}
  }

  // 上一次的数据，比如上一次的catInfo
  #lastListData = {}

  get #cccFeedbackInfo() {
    return this.data.bffCccFeedbackInfo || {}
  }

  get #bffProductsInfo() {
    return this.data.bffProductsInfo || {}
  }

  get #bffFilterInfo() {
    return this.data.bffFilterInfo || {}
  }

  get #listDiffParams() {
    const diffParams = this.#getListDiffParams()
    return {
      ...diffParams,
      isSearchPage: diffParams.type === 'search', // 搜索结果页
      isRealPage: diffParams.type === 'entity', // 真实分类页
      isSelectPage: diffParams.type === 'selection', // 选品页
      isPickPage: diffParams.type === 'pick' // 信息流落地页
    }
  }

  // 字段适配（bff字段 -> 原来的字段）
  adaptData() {
    this.#adaptCatInfo()
    this.#adaptGoods()
    this.#adaptGoodsOtherParameters()
    this.#adaptCardConfig()
    this.#adaptFilters()
    this.#adaptFilterAbtParam()
    this.#adpatSearchResultInterfaceCode()
    this.#adaptSearchKeywords()
    return this.getData()
  }

  getData() {
    if (!(this.#hasBffProductsInfo() || this.#hasBffFilterInfo())) return null
    // 剔除多余的字段
    const removeKeys = ['bffCccFeedbackInfo', 'bffFilterInfo', 'bffProductsInfo']
    return Object.keys(this.data).reduce((data, key) => {
      if (removeKeys.includes(key)) return data
      data[key] = this.data[key]
      return data
    }, {})
  }

  // 构造searchResultInterfaceCode
  #adpatSearchResultInterfaceCode() {
    if (!this.#hasBffProductsInfo()) return
    const buriedPoint = this.#bffProductsInfo.buriedPoint
    if (buriedPoint) {
      this.data.searchResultInterfaceCode = buriedPoint
      return 
    }
    if (this.#bffProductsInfo.code === '0') {
      this.data.searchResultInterfaceCode = 1
      return
    }
    this.data.searchResultInterfaceCode = '4_1001'
  }

  #hasBffProductsInfo() {
    return Object.keys(this.#bffProductsInfo)?.length
  }

  #hasBffFilterInfo() {
    return Object.keys(this.#bffFilterInfo)?.length
  }

  #getListDiffParams() {
    // 真实分类页对应的真实分类id
    const productBffCateId = this.#bffProductsInfo?.cateId
    // 搜索结果匹配的cateid
    const searchCateId = this.#bffProductsInfo?.cate_ids?.[0]
    const search = this.pageType === 'search'
    if (search) {
      return {
        type: 'search',
        pageName: 'page_search',
        cat_id: productBffCateId || searchCateId || '',
        searchWord: this.#bffProductsInfo?.origin_words,
        keywords: this.#bffProductsInfo?.origin_words,
      }
    }

    // // 真实列表页
    // const isRealList = this.pageType === 'entity'
    // if (isRealList) {
    //   return {
    //     type: 'entity',
    //     pageName: 'page_real_class',
    //     entity_id: productBffCateId || isRealList[1],
    //     cat_id: productBffCateId || searchCateId || ''
    //   }
    // }
    // // 选品列表页
    // const isSelectList = this.pageType === 'selection'
    // // 选品页对应的选品真实分类id
    // const filterBffCateId = this.#bffFilterInfo?.cateId
    // // 选品页对应的selectId
    // const filterBffSelectId = this.#bffProductsInfo?.category_id
    // if (isSelectList) {
    //   return {
    //     type: 'selection',
    //     pageName: 'page_select_class',
    //     select_id: filterBffSelectId || isSelectList[3],
    //     selection_entity_id: filterBffCateId || ''
    //   }
    // }

    return {}
  }

  // 成人用品
  #adaptAdultProduct(isSearchPage) {
    if (this.query?.page > 1) {
      // @tips 第二页不请求筛选数据会影响成人弹窗状态
      return this.#lastListData.catInfo?.hasAdultProduct
    }
    const { hasAdultProduct } = this.data.cat_info || {}
    const show_adult_tip = isSearchPage
      ? this.#bffProductsInfo?.show_adult_tip
      : this.data?.bffFilterInfo?.show_adult_tip ||
        this.data?.bffFilterInfo?.showAdultTip
    return hasAdultProduct && show_adult_tip && show_adult_tip !== '0'
  }

  // 构造cat_info
  #adaptCatInfo() {
    /**
     * 一些待做的值，此次搜索融合先不做
     * entity_id catInfo.entity_id || catInfo.select_id
     * select_id catInfo.entity_id || catInfo.select_id return this.catInfo?.select_id
     * hasAdultProduct 真实\选品 和 搜索 不一样
     * showFeedbackRec: this.catInfo.showFeedbackRec !== 0
     *
     *
     * 一些值还是从node取，暂时不融合 TODO:
     * hasAdultProduct: this.hasAdultProduct(this.Request, this.data.cate_ids, this.data.cate_tags), 有些xhr接口的更新逻辑，暂时不融合
     * */

    if (!(this.#hasBffProductsInfo() || this.#hasBffFilterInfo())) return

    const isDefaultChild =
      this.data?.bffFilterInfo?.tagChild?.defaultChild?.child_id // 默认kids流程

    const listParams = this.#listDiffParams
    const cat_info = {
      switchBff: true,
      ...listParams,
      suggestedSaleTypeBff: this.#bffProductsInfo?.suggestedSaleType || '',
      hasAdultProduct: this.#adaptAdultProduct(listParams.isSearchPage),
      isAdultRankSsrBff: this.data.cat_info.isAdultRankSsrBff,
      requestType: this.requestType,
      page: this.query.page,
      limit: this.query.limit,
      search_type: this.query.search_type,
      tsp_ids: this.query.tsp_ids,
      tag_ids:
        this.query.tag_ids || isDefaultChild ? this.data.cat_info.tag_ids : '', // 有默认的tagChild时，tag_ids取值
      min_price: this.query.min_price,
      max_price: this.query.max_price,
      sort: this.query.sort,
      attr_values: this.query.attr_values,
      attr_ids: this.query.attr_ids,
      brand_ids: this.query.brand_ids,
      attr_node_ids: this.query.attr_node_ids,
      child_cat_id: this.query.child_cat_id,
      daily: this.query.daily,
      ici: this.query.ici,
      sil_type: this.query.sil_type,
      ShowTip: this.query.ShowTip,
      child_cat_id_void: this.query.search_id ? true : false,
      search_redir: this.query.search_redir,
      filterWords: this.query.filterWords,
      filterAttrIds: this.query.filterAttrIds, // 待确认是否使用
      store_code: this.query.store_code,
      intent_channel_id: this.query.intent_channel_id,
      url_from: this.query.url_from,
      src_identifier: this.query.src_identifier,
      src_module: this.query.src_module,
      src_tab_page_id: this.query.src_tab_page_id,
      userpath: this.query.userpath,
      is_from_list_feeds: this.query.is_from_list_feeds,
      result_Ioc: this.query.result_Ioc,
      list_cat_name: this.query.list_cat_name,
      lockMall: this.query.lockMall,
      mallCode: this.query.mallCode,
      styleType: this.query.styleType,
      pageListType: this.#bffProductsInfo?.context?.page_list_type || 4,
      sortScene: this.#bffProductsInfo?.sortPoskey,

      kid_id: this.query.kid_id || isDefaultChild ? this.data.cat_info.kid_id : '',
      showAllCategory: this.query.showAllCategory,
      fromPageType: this.query.fromPageType,
      entranceType: this.query.entranceType,
      pageFrom: this.query.pageFrom,
    }
    this.data.cat_info = Object.keys(cat_info).reduce((catInfo, key) => {
      if (cat_info[key] === undefined || cat_info[key] === null) return catInfo
      catInfo[key] = cat_info[key]
      return catInfo
    }, {})
  }

  // 构造searchKeywords - S
  #checkRedirection({ action, page_type, page_id, url }) {
    const { PICKS_LINKS, langPath } = gbCommonInfo || {}
    if (page_type == 40) {
      // 付费会员没有url地址， 提前判断
      url = '/user/prime'
    }
    // cccx url字段只会返回当前配置的page_id，用这个来进行匹配....
    if (page_type == 41) {
      let picksTable = PICKS_LINKS || {}
      let pageId = page_id?.split('/')?.[0] || url
      url = picksTable[pageId] ? `/${picksTable[pageId]}.html` : ''
    }
    // 列表链接分类说明参见： wiki.pageId=192285255
    const page_types = [
      1, // 'search'搜索结果页
      2, // 'real'真实分类页
      // 3, // '-废弃virtual-'
      4, // 'webLink'
      5, // 'itemPicking' 选品分类
      6, // 'sku' 商详
      7, // 'article' 文章
      8, // 'activity' 专题
      9, // 'game' 游戏
      10, // 'lookBook' 套装lookbook
      // 11, // app 专用
      12, // 'store' 第三方商铺
      39, // '新专题'
      40, // '付费会员'
      26, // Category
      16, // DailyNew
      37, // Flashsale
      25, //优惠券中心
      41 // sheinpick
    ]
    if (action !== 'redirect' || !url || !page_types.includes(+page_type))
      return null

    // 拼接完整路径
    url = /^\/[^\/]/.test(url) ? `${langPath}${url}` : `//${url}`

    const mall_code = {}
    if (page_type == 2 || page_type == 5 || page_type == 41) {
      const mallCode = page_id?.split('/')?.[1]
      mallCode && Object.assign(mall_code, { mallCode })
    }
    const queryObj = {
      search_redir: 1,
      search_words: this.data?.cat_info?.searchWord,
      ici: this.data?.cat_info?.ici?.replace('`d0`', '`d1`'),
      src_identifier: this.data?.cat_info?.src_identifier || '',
      src_tab_page_id: this.data?.cat_info?.src_tab_page_id || '',
      src_module: this.data?.cat_info?.src_module || '',
      ...mall_code
    }

    if (page_type == 12) {
      url = `${langPath}/store/home`
      queryObj.store_code = page_id
    }
    return `${url}${url.includes('?') ? '&' : '?'}${stringifyQueryString({
      queryObj
    })}` // 生成最终的
  }
  #adaptSearchKeywords() {
    if (!this.#hasBffProductsInfo() || !this.data.cat_info?.isSearchPage) return
    const { searchWord } = this.data?.cat_info || {}
    const { is_suggest_res, suggest_words, origin_num, double_lang_correct } = this.#bffProductsInfo
    const escapedSearchWord = searchWord
      ?.replace('<', '&lt;')
      .replace('>', '&gt;')
    this.data.searchKeywords = {
      keywords: escapedSearchWord,
      redirectUrl: this.#checkRedirection(this.#bffProductsInfo), // TODO: 先暂时自己生产直达链接的逻辑，后面转到bff
      origin_words: escapedSearchWord,
      origin_total: origin_num,
      is_suggest_res: Number(is_suggest_res) || 0,
      suggest_words,
      double_lang_correct: double_lang_correct === '1' ? true : false,
      suggest_total: suggest_words ? this.data.sum : 0 // sum是要adaptGoodsOtherParameters执行完后才有
    }
  }
  // 构造searchKeywords - E

  // 构造goods
  #adaptGoods() {
    if (!this.#hasBffProductsInfo()) return
    const isSearchPage = this.data.cat_info?.type === 'search'  // 是否搜索结果页
    const storeSearch = isSearchPage && this.data.cat_info?.search_type === 'store'   // 店铺搜索页
    const brandSearch = isSearchPage && this.data.cat_info?.search_type === 'brand'     // 品牌搜索页
    const cccFeedback = this.#cccFeedbackInfo?.extendConfigDetail?.find(
      item => {
        const dataPageStyleKey = this.data.cat_info?.pageStyleKey || this.data.cat_info?.pageName
        return (
          item?.pageStyleKey === dataPageStyleKey &&
          item.sceneKey === 'TWO_IN_A_ROW'
        )
      }
    )
    const getItemInfo = (v) => {
      if (!this.lang) {
        throw new Error('lang is required')
      }
      return goodsTransformation(v, {
        suggestedSaleType: this.#bffProductsInfo?.suggestedSaleType || '',
        language: this.data.language ?? {},
        lang: this.lang,
        isPaid: !!this.data.sheinClubInfo?.isPaid
      })
    }
    this.data.goods = this.#bffProductsInfo.products?.map?.(v => {
      if (cccFeedback) {
        v.cccFeedback = cccFeedback
      }
      const goodsInfo = getItemInfo(v)
      // 品牌搜索&店铺搜索。中间层返回了，但需要屏蔽的功能
      if (brandSearch || storeSearch) {
        if (goodsInfo.showAddButtonLabelStyle) {
          goodsInfo.showAddButtonLabelStyle = ''
        }
      }
      return goodsInfo
    }) || []
  }

  // 构造cardConfig(商卡配置)
  #adaptCardConfig() {
    if (!this.#hasBffProductsInfo() || !this.data.cardConfig) return
    // 标题相关配置
    const goodsNameLineLimit = +this.#bffProductsInfo?.listStyle?.twoColumnStyle?.goodsNameLineLimit
    if ([1, 2].includes(goodsNameLineLimit)) {
      this.data.cardConfig.showTitle = true
      this.data.cardConfig.showTitleTwoLine = goodsNameLineLimit === 2
    }
    // 加车按钮是否显示
    const bffGoodsInfo = this.#bffProductsInfo?.products?.[0] ?? {}
    if (bffGoodsInfo?.productMaterial?.showAddButtonLabel) {
      this.data.cardConfig.showAddBagBtn =
        bffGoodsInfo.productMaterial.showAddButtonLabel === '1'
    }
    // 临时兜底 如果没有返回商品 无结果 则把加车按钮显示出来
    const { page, type } = this.data?.cat_info ?? {}
    if (
      type === 'search' &&
      page === 1 &&
      !bffGoodsInfo?.productMaterial &&
      this.data?.cardConfig
    ) {
      this.data.cardConfig.showAddBagBtn = true
    }
    // urlQuery相关配置
    if (!this.data.cardConfig.urlQuery) this.data.cardConfig.urlQuery = {}
    this.data.cardConfig.urlQuery.pageListType = this.data.cat_info.pageListType
  }

  // 构造筛选（图文，分类/属性/tsp筛选，标签云）
  #adaptFilters() {
    if (!this.#hasBffFilterInfo()) return

    bffFilterAdapter(this.data, this.#bffFilterInfo)
  }

  // 构造filterAbtParam - S
  #formatChildAbt(listFilterAbt, currentId) {
    const isFlag = listFilterAbt?.p?.hasOwnProperty(currentId)
    let child = []
    if (isFlag) {
      const originChild = listFilterAbt.child || []
      child = originChild.filter(item =>
        (item.param || item.p || {}).hasOwnProperty(currentId)
      )
    }
    return Object.assign({}, listFilterAbt || {}, { child, a: 1 })
  }

  #adaptFilterAbtParam() {
    if (!this.#hasBffFilterInfo()) return
    if (!this.data.filterAbtParam) this.data.filterAbtParam = {}
    const listFilterAbt = this.#bffFilterInfo?.webAbtInfo?.listFilterAbt
    const cateId = this.#bffProductsInfo?.cate_ids?.[0]
    const abtParamsJson = listFilterAbt?.['abt-params-json']
    const abtPoskey = listFilterAbt?.['abt-poskey']
    const child = listFilterAbt?.child
    const listFilterAbtFormat = {
      child,
      p: abtParamsJson,
      param: abtParamsJson,
      posKey: abtPoskey,
      poskey: abtPoskey,
      sceneStr: abtPoskey,
      version: 'v4'
    }
    this.data.filterAbtParam.abt = this.#formatChildAbt(
      listFilterAbtFormat,
      cateId
    )
  }

  #adaptGoodsOtherParameters() {
    if (!this.#hasBffProductsInfo()) return
    // 下游返回的request_ext透传
    this.data.request_ext = this.#bffProductsInfo?.request_ext ?? {}
    this.data.cateIds = this.#bffProductsInfo.cateIds || []
    // 商品总数
    this.data.sum = this.#bffProductsInfo.num
    this.data.sumForPage = this.#bffProductsInfo.numForPage
    // 中间层过滤的商品id
    this.data.frontFilterGoods = this.#bffProductsInfo.frontFilterGoods
  }
}

export default BffAdapter
