/**
 * @file 列表页相关api
 */

import schttp from 'public/src/services/schttp/index.js'

// 获取榜单插坑数据
export const fetchRankListComponent = async (params) => {
  const result = await schttp({
    method: 'GET',
    url: '/category/rank_list_component',
    params,
    useBffApi: true,
  })
  if (result?.info) {
    result.info.delivery_place = params.delivery_place - 1
  }
  return result
}
